// pages/_app.js
import '../styles/globals.css';
import Head from 'next/head';
import Layout from '../components/Layout';
import Link from 'next/link'
import Badges from '../components/Badges';


const MyApp = ({ Component, pageProps }) => {
  return (
    <>
      <Layout>
        {/* <Badges /> */}
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

export default MyApp;
