import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import badge1 from "../public/badge1.webp"
import badge2 from "../public/badge2.webp"
const Badges = () => {
  return (
    <>
     <div className="mt-10 md:mt-0 flex flex-col md:flex-row items-center justify-around gap-4">
  {/* Badge 1 */}
  <div className="w-[200px] md:h-40 flex items-center justify-center shrink-0 transform transition duration-300 hover:scale-105 mb-4 md:mb-0">
    <Link href="https://www.softwaresuggest.com/mocha-accounting" target="_blank">
      <Image
        src={badge1}
        alt="Highly Recommended"
        className="max-w-full h-auto"
      />
    </Link>
  </div>

  {/* Badge 2 */}
  <div className="w-[200px] h-40 flex items-center justify-center shrink-0 overflow-hidden transform transition duration-300 hover:scale-105 mb-4 md:mb-0">
    <Link href="https://www.saashub.com/best-accounting-solution" target="_blank">
      <Image
        src={badge2}
        alt="Find us on Saashub"
        className="max-w-full h-auto"
      />
    </Link>
  </div>

  {/* Financial Image */}
  <div className="w-[200px] h-40 flex items-center justify-center shrink-0 overflow-hidden transform transition duration-300 hover:scale-105">
    <Image
      src="/partners/financial.svg"
      alt="Financial"
      width={306}
      height={60}
      loading="lazy"
      className="max-w-full h-auto"
    />
  </div>
</div>




    </>
  );
};

export default Badges;
