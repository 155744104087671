// components/Layout.js

import Footer from './Footer';
import Navbar from './Navbar';

import { Poppins } from 'next/font/google';

const poppins = Poppins({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-poppins',
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
});

const Layout = ({ children }) => {
  return (
    <div className='my-0 mx-auto'>
      <main className={`${poppins.variable} font-sans`}>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
